import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import MarkdownIt from 'markdown-it';

interface Block {
    header: string;
    content: string;
    hash?: string;
}

const blocks: Block[] = [
    {
        header: 'Ich benutze ioBroker nicht. Kann ich trotzdem teilnehmen?',
        content: `
Ja! Du hast einen Raspberry Pi?

Dann richte ioBroker ein oder frage, ob wir noch eingerichtete Raspberry Pis zur Verfügung stellen können.

Fülle dazu das Registrierungsformular aus und gib an, dass du bisher kein ioBroker verwendest.

Darüber hinaus musst du uns deine Smarthome-Geräte beschreiben. Dann kannst du teilnehmen.
`,
        hash: 'no-iobroker',
    },
    {
        header: 'Ich benutze keine (unterstützte) Fritzbox. Kann ich trotzdem teilnehmen?',
        content: `
Leider ist die Teilnahme aktuell nur mit Fritzboxen möglich, welche die Paketmitschnittfunktion unterstützen.
`,
        hash: 'no-normal-fritzbox',
    },
    {
        header: 'Wer steht hinter dem KISSHOME-Projekt?',
        content: `
Das KISSHOME-Projekt ist ein Forschungsprojekt zwischen ARIC e.V., dem Institut für Internet-Sicherheit der Westfälischen Hochschule und dem Institut für Innovationsmarketing / Technische Universität Hamburg. Unser Ziel ist, Smarthome-Umgebungen sicherer zu machen. Weitere Informationen gibt es unter [https://kisshome.de](https://kisshome.de).
`,
        hash: 'who-is-who',
    },
    {
        header: 'Was erhalte ich für die Teilnahme an der Studie?',
        content: `
Nicht nur hilft ihr uns durch eure Teilnahme dabei, die Forschung im Bereich der Sicherheit von Smarthome-Umgebungen voranzutreiben, sondern es gibt auch Prämien, die ihr erhalten könnt.

Einen 50€ Gutschein (Amazon oder etwas Anderes unter Absprache) gibt es für das Zusenden von Daten mindestens dreier cloud-enabled Geräte über einen Zeitraum von mindestens 6 Wochen. 

Weiterhin ist es möglich, einen Raspberry-Pi 5 zu erhalten. Da wir nur eine begrenzte Anzahl haben, sind diese für diejenigen vorgesehen, die ioBroker nicht verwenden, und den Raspi daher für die Teilnahme benötigen, oder diejenigen, die eine besonders ausgeschmückte, für uns interessante Smarthome Umgebung haben.
`,
        hash: 'registration-rewards',
    },
    {
        header: 'Wie und wann erfahre ich, ob ich einen Gutschein für die Teilnahme erhalte?',
        content: `
Gutscheine gibt es für das Zusenden von Daten mindestens dreier cloud-fähiger Geräte über einen Zeitraum von mindestens 6 Wochen. Nach erstmaliger Datenübertragung (spätestens ein paar Stunden nach dem Starten des Mitschneidens im Adapter) erhältst du von uns eine Email, von wie vielen cloudfähigen Geräten wir Daten erhalten haben. Hierzu sollte sichergestellt werden, dass die Geräte in dieser Stunde auch angeschaltet sind und testweise benutzt werden. Sind dies mindestens drei, lasse das Mitschneiden einfach 6 Wochen laufen und du erhältst von uns den Gutschein.
`,
    },
    {
        header: 'Wie und wann erfahre ich, ob ich einen Raspberry-Pi für die Teilnahme erhalte?',
        content: `
Da wir nur eine begrenzte Anzahl an Raspberry-Pis zur Verfügung haben, können wir leider nicht jedem Teilnehmer einen zuschicken. Wird im Registrierungsformular entsprechend angegeben, dass ein Raspberry-Pi 5 erwünscht ist, überprüfen wir deine Angaben und setzen uns unter Umständen mit dir für die Auswahl in Kontakt.
`,
        hash: 'raspi-rewards',
    },
    {
        header: 'Welche Daten werden gesammelt?',
        content: `
An den KISShome-Server werden folgende Daten gesendet:

- Die UUID der ioBroker-Instanz (um verschiedene ioBroker-Installationen auseinanderzuhalten)
- Die MAC-Adressen der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die IP-Adressen der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die IP-Adressen und die Ports, an welche die Geräte Daten senden
- Die Quell- und Ziel-Ports der Geräte, die zur Datenaufzeichnung konfiguriert sind
- Die TCP- und UDP-Header der Pakete. Alles, was nach den Headern kommt, wie zum Beispiel Nutzdaten, wird verworfen.
`,
        hash: 'data-collected',
    },
    {
        header: 'Wie lange werden die Daten gespeichert?',
        content: `
Die Daten werden nach 6 Monaten gelöscht und liegen anschließend nur noch in anonymisierter Form vor. Sie können jederzeit die Löschung Ihrer Daten beantragen.
Schreiben Sie uns hierzu von Ihrer registrierten E-Mail-Adresse an: [kisshome@internet-sicherheit.de](mailto:kisshome@internet-sicherheit.de).`,
        hash: 'data-retention',
    },
    {
        header: 'Wer hat Zugriff auf die Daten?',
        content: `
Nur Mitarbeitende des KISSHOME-Projekts im Institut für Internet-Sicherheit an der Westfälischen Hochschule Gelsenkirchen haben Zugriff auf die Daten. Die Daten werden zu keinem Zeitpunkt an Dritte weitergegeben.
`,
        hash: 'data-access',
    },
    {
        header: 'Sonstige Fragen zur Teilnahme an der Studie oder zum KISSHOME Projekt?',
        content: `
Unklarheiten oder weitere Fragen rund um diese Studie, den Datenschutz, unsere Forschungsziele, usw. können gerne direkt an uns unter kisshome@internet-sicherheit.de gestellt werden. 
`,
        hash: 'other-questions',
    },
];

const Faq = () => {
    const md = new MarkdownIt({ html: true });
    const page = window.location.hash.split('/')[2];
    const [activeKey, setActiveKey] = React.useState<number | null>(page ? blocks.findIndex(block => block.hash === page) : null);

    useEffect(() => {
        if (activeKey !== -1 && activeKey !== null) {
            setTimeout(() => {
                const el = document.getElementById(`faq-${activeKey}`);
                if (el) {
                    const y = el.getBoundingClientRect().top + window.scrollY - 105;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }, 100);
        }
    }, []);

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
        }}
    >
        <h3>Häufig gestellte Fragen</h3>
        <Accordion
            defaultActiveKey={activeKey !== null ? activeKey.toString() : undefined}
        >
            {blocks.map((block, index) => <Accordion.Item
                id={`faq-${index}`}
                eventKey={index.toString()}
                key={index.toString()}
            >
                <Accordion.Header
                    onClick={() => {
                        setActiveKey(index);
                        window.location.hash = `#/faq/${block.hash}`;
                    }}
                >
                    <span style={{ fontWeight: 'bold' }}>{block.header}</span>
                </Accordion.Header>
                <Accordion.Body>
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: md.render(block.content) || 'Lade...' }}
                    />
                </Accordion.Body>
            </Accordion.Item>)}
        </Accordion>
    </div>;
};

export default Faq;
