import React, { useEffect, useRef, useState } from 'react';
import MarkdownIt from 'markdown-it';

const Privacy = () => {
    const mdRef = useRef<MarkdownIt>();
    const [mdText, setMdText] = useState<string | null>(null);

    useEffect(() => {
        mdRef.current = new MarkdownIt({ html: true });
        fetch('privacy.md')
            .then(response => response.text())
            .then(text => setMdText(text));
    }, []);

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
        }}
    >
        <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: mdText && mdRef.current ? mdRef.current.render(mdText) : 'Lade...' }}
        />
    </div>;
};

export default Privacy;
