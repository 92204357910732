import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { type EnqueueSnackbar, useSnackbar } from 'notistack';
import { getParticipants, deleteParticipant, updateParticipant } from '../apiAdmin';
import { type RegisterRequest } from '../types';

let originalData: RegisterRequest | null = null;

async function copy(text: string): Promise<void> {
    try {
        await navigator.clipboard.writeText(text);
        console.log('Content copied to clipboard');
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

function copyContentSvg(
    text: string | undefined,
    enqueueSnackbar: EnqueueSnackbar,
    style?: { top?: number | string; bottom?: number | string; left?: number | string; right?: number | string; display?: string },
): React.JSX.Element | null {
    return text ? <span
        className="copyButton"
        style={style}
        onClick={() => {
            copy(text)
                .then(() => enqueueSnackbar('In die Zwischenablage kopiert', { variant: 'success' }))
                .catch(console.error);
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#111"
        >
            <path
                d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"
            />
        </svg>
    </span> : null;
}

const List = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState<Record<string, RegisterRequest> | null>(null);
    const [login, setLogin] = useState(window.localStorage.getItem('login') || '');
    const [sendeNummer, setSendeNummer] = useState('');
    const [password, setPassword] = useState(window.localStorage.getItem('password') || '');
    const [showLoginDialog, setShowLoginDialog] = useState(!window.localStorage.getItem('login') || !window.localStorage.getItem('password'));
    const [showEditDialog, setShowEditDialog] = useState<RegisterRequest | null>(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState<string>('');
    const [showSentDialog, setShowSentDialog] = useState<string>('');
    const [onlyWithRaspi, setOnlyWithRaspi] = useState(window.localStorage.getItem('onlyWithRaspi') === 'true');
    const [onlyWithoutRaspi, setOnlyWithoutRaspi] = useState(window.localStorage.getItem('onlyWithoutRaspi') === 'true');
    const [onlySentRaspi, setOnlySentRaspi] = useState(window.localStorage.getItem('onlySentRaspi') === 'true');

    useEffect(() => {
        async function fetchData() {
            if (login && password && !showLoginDialog) {
                const data = await getParticipants(login, password);
                setList(data);
            }
        }

        if (!showLoginDialog) {
            fetchData()
                .catch(console.error);
        }
    }, [login, password, showLoginDialog]);

    if (showLoginDialog) {
        return <Modal show={showLoginDialog} onHide={() => setShowLoginDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Bitte einloggen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    <Form.Group>
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            id="login"
                            type="text"
                            required
                            value={login}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            id="password"
                            type="password"
                            required
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button
                        disabled={!password || !login}
                        style={{ backgroundColor: '#223459' }}
                        onClick={() => {
                            window.localStorage.setItem('login', login);
                            window.localStorage.setItem('password', password);
                            setShowLoginDialog(false);
                        }}
                    >
                        Login
                    </Button>
                </div>
            </Modal.Body>
        </Modal>;
    }

    const renderUser = (index: number, email: string, data: RegisterRequest) => <tr key={email}>
        <td>{index + 1}</td>
        <td style={{ color: data.emailConfirmed ? undefined : 'red' }} title={data.emailConfirmed ? '' : 'Email nicht bestätigt'}>{email}</td>
        <td className="copyButtonDiv">
            {data.name}
            {copyContentSvg(data.name, enqueueSnackbar)}
        </td>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <td
            style={{
                fontStyle: data.raspi && data.raspiSent ? 'bold' : undefined,
                color: data.raspi && data.raspiSent ? 'green' : undefined,
                cursor: data.raspi && !data.raspiSent ? 'pointer' : undefined,
            }}
            title={data.raspi && !data.raspiSent ? 'Raspberry PI verschicken' : (data.raspiSent === true ? 'Raspberry PI verschickt' : (data.raspiSent || ''))}
            onClick={async () => {
                if (data.raspi && !data.raspiSent) {
                    setShowSentDialog(email);
                }
            }}
        >
            {data.raspi ? '✓' : '-'}
        </td>
        <td>{data.forum}</td>
        <td>{data.github}</td>
        <td className="copyButtonDiv">
            {data.raspi ? <>
                {data.addressCity}
                {', '}
                {data.addressZip}
                {', '}
                <br />
                {data.addressLine1}
                {data.addressLine2 && ', '}
                {data.addressLine2}
                {copyContentSvg(`${data.addressLine1}, ${data.addressLine2 ? `${data.addressLine2}, ` : ''}${data.addressZip} ${data.addressCity}`, enqueueSnackbar)}
            </> : ''}
        </td>
        <td>
            <Button
                onClick={async () => {
                    originalData = JSON.parse(JSON.stringify(data));
                    setShowEditDialog(JSON.parse(JSON.stringify(data)));
                }}
                style={{ marginRight: 8 }}
            >
                Editieren
            </Button>
            <Button onClick={async () => setShowConfirmDialog(email)}>
                Löschen
            </Button>
        </td>
    </tr>;
    let confirmDialog = null;

    if (showConfirmDialog) {
        confirmDialog = <Modal show={!!showConfirmDialog} onHide={() => setShowConfirmDialog('')}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Löschen
                    <span style={{ fontWeight: 'bold', marginLeft: 16 }}>{showConfirmDialog}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    <p>Wirklich löschen?</p>
                    <Form.Group style={{ textAlign: 'right' }}>
                        <Button
                            disabled={!password || !login}
                            onClick={() => setShowConfirmDialog('')}
                            style={{ marginRight: 8 }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            disabled={!password || !login}
                            style={{ backgroundColor: '#223459' }}
                            onClick={async () => {
                                try {
                                    await deleteParticipant(login, password, showConfirmDialog);
                                    setList(await getParticipants(login, password));
                                    setShowConfirmDialog('');
                                } catch (error: any) {
                                    enqueueSnackbar(error.response?.data?.err, { variant: 'error' });
                                    console.error(error);
                                }
                            }}
                        >
                            Löschen
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>;
    }

    let sentDialog = null;
    if (showSentDialog) {
        sentDialog = <Modal show={!!showSentDialog} onHide={() => setShowSentDialog('')}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Raspberry PI verschicken an
                    <span style={{ fontWeight: 'bold', marginLeft: 16 }}>{showSentDialog}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    <p>Wurde der Raspberry PI verschickt?</p>
                    <div className="copyButtonDiv">
                        <span style={{ fontWeight: 'bold' }}>Name:</span>
                        <span style={{ marginLeft: 16 }}>
                            {list?.[showSentDialog]?.name}
                            {copyContentSvg(list?.[showSentDialog]?.name, enqueueSnackbar, { top: 0, display: 'block' })}
                        </span>
                    </div>
                    <div className="copyButtonDiv">
                        <span style={{ fontWeight: 'bold' }}>Adresse:</span>
                        <span style={{ marginLeft: 16 }}>
                            {list?.[showSentDialog]?.addressLine1}
                            {list?.[showSentDialog]?.addressLine2 && ', '}
                            {list?.[showSentDialog]?.addressLine2}
                            {', '}
                            {list?.[showSentDialog]?.addressZip}
                            {' '}
                            {list?.[showSentDialog]?.addressCity}
                        </span>
                        {copyContentSvg(`${list?.[showSentDialog]?.addressLine1}, ${list?.[showSentDialog]?.addressLine2 ? `${list[showSentDialog].addressLine2}, ` : ''}${list?.[showSentDialog]?.addressZip} ${list?.[showSentDialog]?.addressCity}`, enqueueSnackbar, { top: 0, display: 'block' })}
                    </div>
                    <Form.Group>
                        <Form.Label>DHL Sendenummer</Form.Label>
                        <Form.Control
                            type="text"
                            value={sendeNummer || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSendeNummer(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group style={{ textAlign: 'right' }}>
                        <Button
                            disabled={!password || !login}
                            onClick={() => setShowSentDialog('')}
                            style={{ marginRight: 8 }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            disabled={!password || !login}
                            style={{ backgroundColor: '#223459' }}
                            onClick={async () => {
                                try {
                                    await updateParticipant(login, password, showSentDialog, { raspiSent: sendeNummer || true });
                                    setList(await getParticipants(login, password));
                                    setShowSentDialog('');
                                    setSendeNummer('');
                                } catch (error: any) {
                                    enqueueSnackbar(error.response?.data?.err, { variant: 'error' });
                                    console.error(error);
                                }
                            }}
                        >
                            Versenden
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>;
    }

    let editDialog = null;
    if (showEditDialog) {
        editDialog = <Modal show={!!showEditDialog} onHide={() => setShowEditDialog(null)}>
            <Modal.Header closeButton>
                <Modal.Title>{showEditDialog.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.name || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, name: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            label="Email bestätigt"
                            checked={!!showEditDialog.emailConfirmed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, emailConfirmed: e.target.checked })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            label="Raspi angefragt"
                            checked={!!showEditDialog.raspi}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, raspi: e.target.checked })}
                        />
                    </Form.Group>
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Adresszeile 1</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.addressLine1 || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, addressLine1: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Adresszeile 2</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.addressLine2 || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, addressLine2: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>PLZ</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.addressZip || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, addressZip: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Stadt</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.addressCity || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, addressCity: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Forum</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.forum || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, forum: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>GitHub</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.github || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, github: e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Sendenummer</Form.Label>
                        <Form.Control
                            type="text"
                            value={showEditDialog.raspiSent === true ? 'true' : showEditDialog.raspiSent || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowEditDialog({ ...showEditDialog, raspiSent: e.target.value === 'true' ? true : e.target.value })}
                        />
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Geräteanzahl</Form.Label>
                        <Form.Select
                            value={showEditDialog.numberOfDevices || ''}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setShowEditDialog({
                                ...showEditDialog,
                                numberOfDevices: e.target.value,
                            })}
                        >
                            <option value="">Anzahl Geräte</option>
                            <option value="1-5">1-5</option>
                            <option value="6-20">6-20</option>
                            <option value="21-49">21-49</option>
                            <option value="50+">50+</option>
                        </Form.Select>
                    </Form.Group> : ''}
                    {showEditDialog.raspi ? <Form.Group>
                        <Form.Label>Beschreibung</Form.Label>
                        <textarea
                            className="form-control"
                            style={{ width: '100% ' }}
                            value={showEditDialog.description || ''}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setShowEditDialog({ ...showEditDialog, description: e.target.value })}
                        />
                    </Form.Group> : ''}
                    <Form.Group style={{ textAlign: 'right' }}>
                        <Button
                            disabled={!password || !login}
                            onClick={() => setShowEditDialog(null)}
                            style={{ marginRight: 8 }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            disabled={!password || !login}
                            style={{ backgroundColor: '#223459' }}
                            onClick={async () => {
                                try {
                                    const updateData: Partial<RegisterRequest> = {};
                                    Object.keys(showEditDialog).forEach(key => {
                                        if (originalData && (showEditDialog as unknown as Record<string, string | boolean>)[key] !== (originalData as unknown as Record<string, string | boolean>)[key]) {
                                            (updateData as Record<string, string | boolean>)[key] = (showEditDialog as unknown as Record<string, string | boolean>)[key];
                                        }
                                    });

                                    if (Object.keys(updateData).length) {
                                        await updateParticipant(login, password, showEditDialog.email, updateData);
                                        setList(await getParticipants(login, password));
                                    }
                                    setShowEditDialog(null);
                                } catch (error: any) {
                                    enqueueSnackbar(error.response?.data?.err, { variant: 'error' });
                                    console.error(error);
                                }
                            }}
                        >
                            Speichern
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>;
    }

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 5,
            }}
        >
            <Button
                onClick={() => {
                    if (onlySentRaspi) {
                        setOnlySentRaspi(false);
                        window.localStorage.setItem('onlySentRaspi', 'false');
                    } else {
                        setOnlySentRaspi(true);
                        setOnlyWithRaspi(true);
                        setOnlyWithoutRaspi(false);
                        window.localStorage.setItem('onlySentRaspi', 'true');
                    }
                }}
                style={{
                    backgroundColor: onlySentRaspi ? '#223459' : '#FFF',
                    color: onlySentRaspi ? '#FFF' : '#223459',
                }}
            >
                Raspberry PI Versendet
            </Button>
            <Button
                onClick={() => {
                    if (onlyWithRaspi) {
                        setOnlySentRaspi(false);
                        setOnlyWithRaspi(false);
                        window.localStorage.setItem('onlyWithRaspi', 'false');
                    } else {
                        setOnlySentRaspi(false);
                        setOnlyWithRaspi(true);
                        setOnlyWithoutRaspi(false);
                        window.localStorage.setItem('onlyWithRaspi', 'true');
                    }
                }}
                style={{
                    backgroundColor: onlyWithRaspi ? '#223459' : '#FFF',
                    color: onlyWithRaspi ? '#FFF' : '#223459',
                }}
            >
                Mit Raspberry PI
            </Button>
            <Button
                onClick={() => {
                    if (onlyWithoutRaspi) {
                        setOnlyWithoutRaspi(false);
                        window.localStorage.setItem('onlyWithoutRaspi', 'false');
                    } else {
                        setOnlySentRaspi(false);
                        setOnlyWithoutRaspi(true);
                        setOnlyWithRaspi(false);
                        window.localStorage.setItem('onlyWithoutRaspi', 'true');
                    }
                }}
                style={{
                    backgroundColor: onlyWithoutRaspi ? '#223459' : '#FFF',
                    color: onlyWithoutRaspi ? '#FFF' : '#223459',
                }}
            >
                Ohne Raspberry PI
            </Button>
        </div>
        {editDialog}
        {confirmDialog}
        {sentDialog}
        <table
            style={{ width: '100%' }}
            className="list"
        >
            <thead>
                <tr style={{ backgroundColor: '#6a6a6a', color: '#fff', fontWeight: 'bold' }}>
                    <td style={{ width: 20 }}></td>
                    <td>Email</td>
                    <td>Name</td>
                    <td style={{ width: 50 }}>Raspi</td>
                    <td>Forum</td>
                    <td>Github</td>
                    <td>Adresse</td>
                    <td style={{ width: 200 }}></td>
                </tr>
            </thead>
            <tbody>
                {list ? Object.keys(list)
                    .filter(email => {
                        if (onlySentRaspi) {
                            return list[email].raspiSent;
                        }
                        if (onlyWithRaspi) {
                            return list[email].raspi;
                        }
                        if (onlyWithoutRaspi) {
                            return !list[email].raspi;
                        }
                        return true;
                    })
                    .map((email, index) =>
                        renderUser(index, email, list[email])) : <tr><td>Lade...</td></tr>}
            </tbody>
        </table>
    </div>;
};

export default List;
