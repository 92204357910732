import React, { useEffect, useRef, useState } from 'react';
import {
    Accordion, Button, Form, Modal, Nav,
} from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import MarkdownIt from 'markdown-it';
import { type RegisterRequest } from '../types';
import { isOrderPossible, register } from '../api';
import {Link} from "react-router-dom";

const styles = {
    input: {
        maxWidth: 400,
    },
};

const FormPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [validated, setValidated] = useState(false);
    const [orderPossible, setOrderPossible] = useState<boolean | null>(null);
    const [tosOpen, setTosOpen] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [mdText, setMdText] = useState<null | string>(null);
    const [working, setWorking] = useState(false);
    const mdRef = useRef<MarkdownIt>();

    const [form, setForm] = useState<RegisterRequest>({
        email: window.localStorage.getItem('email') || '',
        name: window.localStorage.getItem('name') || '',
        acknowledged: false,
        raspi: false,
        fritzbox: false,
        addressCity: '',
        addressZip: '',
        addressLine1: '',
        addressLine2: '',
        forum: '',
        github: '',
        country: 'DE',
        numberOfDevices: '',
        description: '',
        iobroker: false,
    });

    useEffect(() => {
        if (form.name) {
            window.localStorage.removeItem('name');
        }
        if (form.email) {
            window.localStorage.removeItem('email');
        }

        isOrderPossible()
            .then(() => setOrderPossible(true))
            .catch(() => setOrderPossible(true));

        mdRef.current = new MarkdownIt({ html: true });
        fetch('agb.md')
            .then(response => response.text())
            .then(text => setMdText(text));
    }, []);

    return isSubmitted ? <h3>Formular eingereicht. Überprüfen Sie Ihre E-Mail auf den Bestätigungslink.</h3> : <>
        <Modal
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            style={{ minWidth: 550 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Bitte die Daten vom Absenden überprüfen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    <Form.Group>
                        <Form.Label>Email-Adresse*</Form.Label>
                        <Form.Control
                            type="email"
                            value={form.email}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name*</Form.Label>
                        <Form.Control
                            type="text"
                            value={form.name}
                            disabled
                        />
                    </Form.Group>
                    {form.raspi ? <>
                        <Form.Group>
                            <Form.Label>Ich setze bereits ioBroker ein</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.iobroker ? 'Ja' : 'Nein'}
                                disabled
                            />
                        </Form.Group>
                        {(form.github || '').trim() ?
                            <Form.Group>
                                <Form.Label>GitHub Nickname</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={form.github}
                                    disabled
                                />
                            </Form.Group> : null}
                        {form.forum && (form.forum || '').trim() ? <Form.Group>
                            <Form.Label>ioBroker Forum Nickname</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.forum}
                                disabled
                            />
                        </Form.Group> : null}
                        <Form.Group>
                            <Form.Label>Adresszeile 1*</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.addressLine1}
                                disabled
                            />
                        </Form.Group>
                        {(form.addressLine2 || '').trim() ? <Form.Group>
                            <Form.Label>Adresszeile 2</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.addressLine2}
                                disabled
                            />
                        </Form.Group> : null}
                        <Form.Group>
                            <Form.Label>Stadt*</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.addressCity}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>PLZ*</Form.Label>
                            <Form.Control
                                type="text"
                                value={form.addressZip}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Land</Form.Label>
                            <Form.Control
                                as="select"
                                disabled
                                value={form.country}
                            >
                                <option value="DE">Deutschland</option>
                            </Form.Control>
                        </Form.Group>
                        {form.numberOfDevices ?
                            <Form.Group>
                                <Form.Label>Anzahl von verwendeten Smart-Geräten</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={form.numberOfDevices}
                                    disabled
                                />
                            </Form.Group> : null}
                        {(form.description || '').trim() ? <Form.Group>
                            <Form.Label>Kurze Beschreibung von der SmartHome Umgebung:</Form.Label>
                            <textarea
                                className="form-control"
                                style={{ width: '100%' }}
                                value={form.description}
                                readOnly
                            />
                        </Form.Group> : null}
                    </> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalOpen(false)} disabled={working}>
                    Daten korrigieren
                </Button>
                <Button
                    variant="primary"
                    disabled={working}
                    onClick={async () => {
                        setWorking(true);
                        try {
                            await register(form);
                            setIsSubmitted(true);
                            setModalOpen(false);
                        } catch (error: any) {
                            const text = error.response?.data?.err || error?.toString() || 'Ein Fehler ist aufgetreten';
                            enqueueSnackbar(text, { variant: 'error' });
                            console.error(error);
                        }
                        setWorking(false);
                    }}
                >
                    Absenden
                </Button>
            </Modal.Footer>
        </Modal>
        <Form
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
            }}
            onSubmit={async e => {
                e.preventDefault();
                e.stopPropagation();
                if (e.currentTarget.checkValidity()) {
                    setModalOpen(true);
                }
                setValidated(true);
            }}
            noValidate
            validated={validated}
        >
            <h2>
                Registrierung
            </h2>
            <p>
                Falls Sie an der Studie teilnehmen möchten, füllen Sie bitte das Formular aus.
            </p>
            <Form.Group style={styles.input}>
                <Form.Label>Email-Adresse*</Form.Label>
                <Form.Control
                    type="email"
                    id="email"
                    placeholder="name@example.com"
                    required
                    maxLength={150}
                    disabled={working}
                    value={form.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, email: e.target.value });
                    }}
                />
            </Form.Group>
            <Form.Group style={styles.input}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Max Mustermann"
                    disabled={working}
                    id="name"
                    maxLength={150}
                    required
                    value={form.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, name: e.target.value });
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Check
                    type="checkbox"
                    disabled={working}
                    required
                    label="Ich versichere, dass ich einen kompatiblen Fritz!Box-Router einsetze und ein Smarthome betreibe *"
                    checked={form.fritzbox}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, fritzbox: e.target.checked });
                    }}
                />
                {!form.fritzbox && <Nav.Item>
                    <Nav.Link eventKey="/imprint">
                        <Link
                            to="/fritzbox"
                            onClick={() => {
                                if (form.name) {
                                    window.localStorage.setItem('name', form.name);
                                }
                                if (form.email) {
                                    window.localStorage.setItem('email', form.email);
                                }
                            }}
                        >
                            <span
                                className="link"
                                style={{
                                    marginLeft: 24,
                                    textDecoration: 'underline',
                                    opacity: 0.7,
                                    fontSize: 12,
                                    fontStyle: 'italic',
                                    fontWeight: 400,
                                    fontFamily: 'Roboto, sans-serif',
                                }}
                            >
                                Verwende ich eine kompatible Fritz!Box?
                            </span>
                        </Link>
                    </Nav.Link>
                </Nav.Item>}
            </Form.Group>
            {form.fritzbox ? <Form.Group>
                <Accordion activeKey={tosOpen ? '0' : ''}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setTosOpen(!tosOpen)}>Teilnahmebedingungen</Accordion.Header>
                        <Accordion.Body>
                            <div style={{ height: 600, overflowY: 'auto' }}>
                                <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: mdText && mdRef.current ? mdRef.current.render(mdText) : 'Lade...' }}
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Form.Group> : null}
            {form.fritzbox ? <Form.Group>
                <Form.Check
                    type="checkbox"
                    label="Ich akzeptiere die Teilnahmebedingungen und Datenschutzbestimmungen *"
                    checked={form.acknowledged}
                    required
                    disabled={!form.name || !form.email.match(/^.+@.+\..+$/) || working}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, acknowledged: e.target.checked });
                        setTosOpen(!e.target.checked);
                    }}
                />
            </Form.Group> : null}
            {orderPossible === null ? '...' : null}
            {orderPossible === false ? 'Leider sind alle Raspberry PIs schon vergeben' : null}
            {orderPossible && form.acknowledged && form.fritzbox ? <Form.Group>
                <Form.Check
                    type="checkbox"
                    disabled={!orderPossible || working}
                    label="Ich möchte für die Bereitstellung eines Raspberry Pi 5 berücksichtigt werden"
                    checked={form.raspi}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, raspi: e.target.checked });
                    }}
                />
                {orderPossible && form.acknowledged ? <div style={{ fontSize: 'smaller', fontStyle: 'italic', marginLeft: 26 }}>Im Zweifel müssen wir hierzu Kontakt mit Ihnen aufnehmen.</div> : null}
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.fritzbox ? <Form.Group>
                <Form.Check
                    type="checkbox"
                    disabled={!orderPossible || working}
                    label="Ich setze bereits ioBroker ein"
                    checked={form.iobroker}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, iobroker: e.target.checked });
                    }}
                />
                {orderPossible && form.acknowledged ? <div style={{ fontSize: 'smaller', fontStyle: 'italic', marginLeft: 26 }}>Im Zweifel müssen wir hierzu Kontakt mit Ihnen aufnehmen.</div> : null}
            </Form.Group> : null}
            {orderPossible && form.raspi && form.fritzbox ?
                <div>Optional: Sie können Ihren Nickname im iobroker-Forum oder auf GitHub angeben, wenn Sie möchten. Diese Angabe ist freiwillig. Sie helfen uns bei der Auswahl der Bereitstellung von Raspberry Pis.</div> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>GitHub Nickname</Form.Label>
                <Form.Control
                    type="text"
                    id="github"
                    placeholder="CoderOnGithub"
                    maxLength={100}
                    value={form.github}
                    disabled={working}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, github: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>ioBroker Forum Nickname</Form.Label>
                <Form.Control
                    type="text"
                    id="forum"
                    maxLength={100}
                    disabled={working}
                    value={form.forum}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, forum: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Adresszeile 1*</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Alexanderplatz 1"
                    id="addressLine1"
                    disabled={working}
                    maxLength={200}
                    required={form.raspi}
                    value={form.addressLine1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, addressLine1: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Adresszeile 2</Form.Label>
                <Form.Control
                    type="text"
                    id="addressLine2"
                    disabled={working}
                    placeholder="Zimmer 123"
                    maxLength={200}
                    value={form.addressLine2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, addressLine2: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Stadt*</Form.Label>
                <Form.Control
                    type="text"
                    id="city"
                    placeholder="Berlin"
                    disabled={working}
                    maxLength={100}
                    required={form.raspi}
                    value={form.addressCity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, addressCity: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>PLZ*</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="12345"
                    id="zip"
                    maxLength={5}
                    disabled={working}
                    required={form.raspi}
                    value={form.addressZip}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setForm({ ...form, addressZip: e.target.value });
                    }}
                />
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Land</Form.Label>
                <Form.Control
                    as="select"
                    disabled
                    value={form.country}
                >
                    <option value="DE">Deutschland</option>
                </Form.Control>
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Anzahl von verwendeten Smart-Geräten*</Form.Label>
                <Form.Select
                    disabled={working}
                    value={form.numberOfDevices}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setForm({ ...form, numberOfDevices: e.target.value });
                    }}
                >
                    <option value="">Anzahl Geräte</option>
                    <option value="1-5">1-5</option>
                    <option value="6-20">6-20</option>
                    <option value="21-49">21-49</option>
                    <option value="50+">50+</option>
                </Form.Select>
            </Form.Group> : null}
            {orderPossible && form.acknowledged && form.raspi && form.fritzbox ? <Form.Group style={styles.input}>
                <Form.Label>Kurze Beschreibung von der SmartHome Umgebung:</Form.Label>
                <textarea
                    style={{ ...styles.input, width: '100%' }}
                    disabled={working}
                    maxLength={512}
                    className="form-control"
                    value={form.description}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setForm({ ...form, description: e.target.value });
                    }}
                />
                <div style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                    Art von Geräten? Kann ich mich als Experte bezeichnen? Wie intensiv werden die Geräte benutzt? etc.
                    <br />
                    Diese Beschreibung hilft uns bei der Auswahl der Bereitstellung von Raspberry Pis.
                </div>
            </Form.Group> : null}
            <Form.Group>
                <Button
                    style={{ backgroundColor: '#223459' }}
                    disabled={working || !form.acknowledged || !form.fritzbox ||
                        !form.email.match(/^.+@.+\..+$/) || !form.name ||
                        (form.raspi && (!form.addressCity || !form.addressZip || !form.addressLine1 || !form.numberOfDevices))}
                    type="submit"
                >
                    Absenden
                </Button>
            </Form.Group>
            <Form.Group>
                <div style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                    * Pflichtfelder
                </div>
            </Form.Group>
        </Form>
    </>;
};

export default FormPage;
