import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Container, Nav } from 'react-bootstrap';
import {
    Link, Route,
    Routes, useLocation,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import logo from './assets/KISSHome-Logo1.svg';
import Main from './pages/Main';
import Form from './pages/Form';
import ConfirmEmail from './pages/ConfirmEmail';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';
import FritzBox from './pages/FritzBox';
import List from './pages/List';
import Faq from './pages/FAQ';

function App() {
    const location = useLocation();
    return  <div className="App">
        <div
            className="header"
            style={{
                backgroundColor: '#266AA6',
                display: 'flex',
                alignItems: 'center',
                height: 100,
                position: 'fixed',
                width: '100%',
                zIndex: 3,
            }}
        >
            <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <a href="/">
                    <img src={logo} alt="KISSHome" style={{ height: 80 }} />
                </a>
                <Nav variant="underline" activeKey={location.pathname}>
                    <Nav.Item>
                        <Nav.Link eventKey="/"><Link to="/">Die Studie</Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/register"><Link to="/register">Registrierung</Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/fritzbox"><Link to="/fritzbox">Unterstützte Fritz!Box</Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/faq"><Link to="/faq">FAQ</Link></Nav.Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </div>
        <div
            style={{
                display: 'grid',
                gridTemplateRows: 'auto min-content',
                minHeight: '100vh',
            }}
        >
            <div
                className="content"
                style={{
                    paddingTop: 60,
                    paddingBottom: 60,
                    marginTop: 100,
                }}
            >
                <Container>
                    <SnackbarProvider>
                        <Routes>
                            <Route path="/" Component={Main} />
                            <Route path="/register" Component={Form} />
                            <Route path="/confirm-email" Component={ConfirmEmail} />
                            <Route path="/privacy" Component={Privacy} />
                            <Route path="/imprint" Component={Imprint} />
                            <Route path="/fritzbox" Component={FritzBox} />
                            <Route path="/list" Component={List} />
                            <Route path="/faq" Component={Faq} />
                            <Route path="/faq/:num" Component={Faq} />
                        </Routes>
                    </SnackbarProvider>
                </Container>
            </div>
            <div style={{
                backgroundColor: '#223459',
                display: 'flex',
                alignItems: 'center',
                height: 100,
            }}
            >
                <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={logo} alt="KISSHome" style={{ height: 80 }} />
                    <Nav variant="underline" activeKey={location.pathname}>
                        <Nav.Item>
                            <Nav.Link eventKey="/imprint">
                                <Link to="/imprint">
                                    Impressum
                                </Link>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="/privacy">
                                <Link to="/privacy">
                                    Datenschutz
                                </Link>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </div>
        </div>
    </div>;
}

export default App;
