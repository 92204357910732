import { OpenAPIClientAxios } from 'openapi-client-axios';
import { type RegisterRequest } from './types';

const client = new OpenAPIClientAxios({ definition: window.location.port === '3000' ? 'http://localhost:8000/definition' : '/definition' })
    .init();

export const confirmEmail = async (code: string) => {
    await (await client).confirmEmail({}, { code });
};

export const register = async (data: RegisterRequest) => {
    await (await client).register({}, data);
};

export const isOrderPossible = async () => {
    await (await client).orderPossible();
};

export const isCertificatesAvailable = async () => {
    await (await client).certificatesAvailable();
};

export default client;
