import { OpenAPIClientAxios } from 'openapi-client-axios';
import type { OpenAPIClient } from 'openapi-client-axios/client';
import { type RegisterRequest } from './types';

let client: Promise<OpenAPIClient>;

function init(user: string, pass: string) {
    client = client || new OpenAPIClientAxios({
        definition: window.location.port === '3000' ? 'http://localhost:8000/definition' : '/definition',
        axiosConfigDefaults: {
            auth: {
                username: user,
                password: pass,
            },
        },
    })
        .init();
}

export const getParticipants = async (user: string, pass: string): Promise<Record<string, RegisterRequest>> => {
    init(user, pass);
    return (await (await client).participants()).data as unknown as Record<string, RegisterRequest>;
};

export const deleteParticipant = async (user: string, pass: string, email: string) => {
    init(user, pass);
    const normalClient = await client;

    await normalClient.participantDelete(email);
};

export const updateParticipant = async (user: string, pass: string, email: string, data: Partial<RegisterRequest>) => {
    init(user, pass);
    const normalClient = await client;

    await normalClient.participantPatch(email, data);
};
