import React, { useState, useEffect } from 'react';
import { type AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import { confirmEmail } from '../api';

const ConfirmEmail = () => {
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [working, setWorking] = useState(false);
    const { search } = useLocation();

    const testCode = async () => {
        setWorking(true);
        try {
            await confirmEmail(new URLSearchParams(search).get('code')!);
            setEmailConfirmed(true);
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        } catch (e) {
            setEmailError((e as AxiosError<{ err: string }>).response?.data.err as string);
        }
        setWorking(false);
    };
    useEffect(() => {
        testCode()
            .catch(e => console.error(e));
    }, []);

    return <div>
        {emailError ? <h2 style={{ color: '#F00' }}>{emailError}</h2> : (!working && emailConfirmed ?
            <h1>E-Mail-Adresse ist bestätigt</h1> : <div>E-Mail-Adresse wird geprüft...</div>)}
    </div>;
};

export default ConfirmEmail;
